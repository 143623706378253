<script lang="ts">
	import type { TripsFieldsFragment } from '$src/lib/queries/fragments/generated/TripsFields'
	import { override } from '$src/lib/utils/classnames'
	import PhotoLoop from './PhotoLoop.svelte' // Make sure to import the PhotoLoop component
	import { constructCdnUrl } from '$lib/utils/cloudflare-loader'
	import { fixTitleCharacters } from '$lib/utils/title-case'
	import TripSummary from './TripSummary.svelte'
	import Amount from '../display/Amount.svelte'
	import { toAmount } from '$lib/utils/currency'
	import { browser } from '$app/environment'
	import SkeletonBlock from '../loading/SkeletonBlock.svelte'
	import { stopPropagation } from '$lib/utils/clickHelpers'
	import { goto } from '$app/navigation'
	import { urlState } from '$lib/stores/url-state'
	import SleekButton from '$components/buttons/SleekButton.svelte'
	import { isMobile } from '$src/lib/utils/isMobile'
	import HighlightContainerImage from './HighlightContainerImage.svelte'
	import { MediaType } from '$src/lib/graphql/enums'
	import blockState from '$lib/stores/block-state'
	import Organizer from './Organizer.svelte'
	import Button from '$components/buttons/Button.svelte'
	import { sanitizeAllTagsHtml } from '$src/lib/utils/sanitize'

	interface Props {
		trip: TripsFieldsFragment
		fetching?: boolean
		vertical?: boolean
		isPreview?: boolean
		shouldShowReview?: boolean
		basePrice?: number
		currency?: string
		categories?: string[]
		weekendMinCost?: number
		weekendMaxCost?: number
		weekMinCost?: number
		weekMaxCost?: number
		monthMinCost?: number
		monthMaxCost?: number
		avgReviewRating?: number
		numReviews?: number
		onclick?: (id: string) => void
		onmouseenter?: (id: string) => void
	}

	let {
		trip,
		fetching = $bindable(false),
		vertical = false,
		isPreview = false,
		basePrice = 0,
		currency = 'USD',
		categories = [],
		weekendMinCost = 0,
		weekendMaxCost = 0,
		weekMinCost = 0,
		weekMaxCost = 0,
		monthMinCost = 0,
		monthMaxCost = 0,
		avgReviewRating = null,
		numReviews = null,
		onclick,
		onmouseenter,
		shouldShowReview = false,
	}: Props = $props()

	let sanitizedDescription = $state('')

	let trimmedDescription = $state('')
	let { isPublic, isListing, isProfile } = $derived($urlState)
	let isFullScreen = $derived($blockState?.isFullScreen)
	let MAX_DESCRIPTION_LENGTH = $derived(isFullScreen ? 240 : 150)
	let MAX_DESCRIPTION_LENGTH_NO_ADD_ON = $derived(isFullScreen ? 440 : 240)
	let hasCategories = $derived(categories?.length > 0)
	let hasCosts = $derived(weekendMinCost > 2900 || weekMinCost > 2900 || monthMinCost > 2900)

	$effect(() => {
		if (hasCategories || hasCosts) {
			if (sanitizedDescription.length > MAX_DESCRIPTION_LENGTH) {
				trimmedDescription = sanitizedDescription.slice(0, MAX_DESCRIPTION_LENGTH) + '...'
			} else {
				trimmedDescription = sanitizedDescription
			}
		} else {
			if (sanitizedDescription.length > MAX_DESCRIPTION_LENGTH_NO_ADD_ON) {
				trimmedDescription = sanitizedDescription.slice(0, MAX_DESCRIPTION_LENGTH_NO_ADD_ON) + '...'
			} else {
				trimmedDescription = sanitizedDescription
			}
		}
	})

	function handleClick(event: MouseEvent | TouchEvent) {
		event.preventDefault()
		event.stopPropagation()
		if (!isPreview) fetching = true
		onclick?.(trip.id)
	}

	function handleMouseEnter(event: MouseEvent | TouchEvent) {
		event.preventDefault()
		event.stopPropagation()
		onmouseenter?.(trip.id)
	}

	function handleImageLoadError() {
		pictureUrl = '/visuals/icons/default-user.svg'
	}
	let organizer = $derived(trip?.owner ?? null)
	let pictureUrl = $state('/visuals/icons/default-user.svg')
	$effect(() => {
		pictureUrl =
			organizer?.currentProfilePictureUrl != null && organizer?.currentProfilePictureUrl != ''
				? organizer?.currentProfilePictureUrl
				: '/visuals/icons/default-user.svg'
	})
	$effect(() => {
		if (browser) {
			const rawDescription =
				trip?.description && trip?.description != ''
					? fixTitleCharacters(trip?.description)
					: 'no description'
			sanitizedDescription = sanitizeAllTagsHtml(rawDescription, browser)?.trim()
		}
	})
	let skeletonWidth = $derived(Math.min(100, Math.max(50, (trip?.title?.length ?? 0) * 2)))
	let highlights = $derived(trip?.featuredHighlights ?? [])
	let condensedClasses = $derived(
		override(
			`flex items-center w-full group ${isListing || isProfile ? '' : 'px-4'} ${isPreview ? 'cursor-default' : 'cursor-pointer'}`,
			fetching && !isPreview ? 'animate-pulse' : '',
		),
	)
	let condensedClassesMobile = $derived(
		override(
			`flex flex-col items-start w-full group ${isPreview ? 'cursor-default' : 'cursor-pointer'}`,
			fetching && !isPreview ? 'animate-pulse' : '',
		),
	)
	let condensedClassesVertical = $derived(
		override(
			'flex items-center w-full rounded-lg hover:translate-y-[-4px] transition-transform touch-manipulation',
			fetching && !isPreview ? 'animate-pulse' : '',
		),
	)
	// Prepare the photo URLs for PhotoLoop
	let photoUrls = $derived(
		highlights?.map((highlight) =>
			highlight?.highlight?.cloudflareID
				? constructCdnUrl(highlight?.highlight?.cloudflareID)
				: '/visuals/icons/location-pin.svg',
		) || ['/visuals/icons/location-pin.svg'],
	)

	interface Highlight {
		highlight?: {
			cloudflareID?: string
		}
	}

	// Helper function to repeat array elements up to a specified length
	function repeatArrayElements<T>(arr: T[], targetLength: number): T[] {
		if (!arr?.length) return []
		if (arr.length >= targetLength) return arr

		const repeatedArray: T[] = []
		let index = 0

		while (repeatedArray.length < targetLength) {
			repeatedArray.push(arr[index])
			index = (index + 1) % arr.length
		}

		return repeatedArray
	}

	// Using derived for reactive computation in Svelte 5
	let photoUrlsMobile = $derived.by(() => {
		const cloudflareIDs = highlights
			?.map((highlight: Highlight) => highlight?.highlight?.cloudflareID)
			.filter((id): id is string => id !== undefined)

		return repeatArrayElements(cloudflareIDs, 5)
	})

	function handleReviewClick() {
		if (trip?.yourPurchase) {
			goto(`/review/${trip?.yourPurchase?.id}`)
		}
	}

	let loadedImages = $state(new Set<string>())
	function handleImageLoad(blockId: string) {
		loadedImages.add(blockId)
		loadedImages = loadedImages // trigger reactivity

		isSetLoaded = loadedImages?.size >= minSize
	}

	let minSize = $derived(
		(photoUrlsMobile?.length ?? 0 > 0) ? Math.min(photoUrlsMobile?.length ?? 0, 5) : 5,
	)
	let isSetLoaded = $state(false)
</script>

<div class="relative">
	{#if vertical}
		<button
			onclick={stopPropagation(handleClick)}
			onmouseenter={handleMouseEnter}
			class={condensedClassesVertical}
		>
			<div
				class="flex flex-col bg-brand-gray-5 items-center overflow-hidden max-w-sm w-full shadow-lg mx-auto"
			>
				<div class="relative flex-shrink-0 w-full">
					{#if photoUrls?.length > 0}
						<div class="h-52 w-full overflow-hidden flex-shrink-0">
							<PhotoLoop {vertical} photos={photoUrls} interval={800} />
						</div>
					{:else}
						<div
							class="h-32 w-full bg-brand-gray-4 overflow-hidden flex-shrink-0 flex items-center justify-center"
						>
							<img
								class="h-12 w-12 m-auto rounded-lg object-cover"
								src={'/visuals/icons/location-pin.svg'}
								alt={trip?.title}
							/>
						</div>
					{/if}
					<img
						src={pictureUrl}
						alt={organizer?.currentName}
						onerror={handleImageLoadError}
						class="h-12 w-12 flex-1 rounded-full bg-brand-gray-5 ring-4 ring-offset-0 ring-brand-gray-5 border border-brand-gray-2 absolute -bottom-3 left-4"
					/>
				</div>
				<div
					class={`p-4 pt-5 flex flex-col text-start w-full truncate text-ellipsis ${
						isPreview ? '' : 'hover:opacity-80'
					}`}
				>
					<div class="text-sm font-medium text-black dark:text-white w-full truncate text-ellipsis">
						{fixTitleCharacters(trip?.title)}
					</div>
					<p class="text-xs text-brand-gray-4 dark:text-brand-gray-3 truncate text-ellipsis">
						{@html trimmedDescription}
					</p>
					{#if organizer}
						<div class="flex items-center space-x-2 mt-3 mb-1">
							<TripSummary
								numBlocks={trip?.numBlocks}
								numDestinations={trip?.numDestinations}
								numHighlights={trip?.numHighlights}
								numSources={trip?.numSources}
								isCompact={true}
							/>
						</div>
					{/if}
				</div>
			</div>
		</button>
	{:else if isMobile() || !isFullScreen}
		<div class={condensedClassesMobile}>
			<button
				onclick={stopPropagation(handleClick)}
				onmouseenter={handleMouseEnter}
				disabled={isPreview}
				class={override(
					'text-lg w-full text-start font-normal text-black dark:text-brand-gray-1 truncate text-ellipsis group-hover:dark:text-white transition-all duration-300',
					!isListing && !isProfile ? 'px-4' : '',
				)}
			>
				{fixTitleCharacters(trip?.title)}
			</button>
			{#if photoUrlsMobile?.length > 0}
				<button
					onclick={stopPropagation(handleClick)}
					onmouseenter={handleMouseEnter}
					disabled={isPreview}
					class="relative mt-[10px] opacity-90 group-hover:opacity-100 transition-opacity duration-300 fade-container max-w-full flex flex-nowrap items-center gap-x-1 overflow-x-scroll overflow-y-clip hide-scrollbar"
				>
					{#each photoUrlsMobile?.slice(0, 5) as photo, index}
						<HighlightContainerImage
							mediaType={MediaType.Image}
							cloudflareID={photo}
							animationDelay={index * 100}
							isLoaded={true}
							onload={() => handleImageLoad(photo)}
						/>
					{/each}
				</button>
			{/if}
			<button
				onclick={stopPropagation(handleClick)}
				onmouseenter={handleMouseEnter}
				disabled={isPreview}
				class={`mt-2 h-auto flex flex-col text-start justify-between truncate text-ellipsis w-full touch-manipulation ${
					isPreview ? '' : 'group-hover:text-white'
				} ${!isListing && !isProfile ? 'px-4' : ''}
				`}
			>
				<div class="flex flex-col w-full">
					<Organizer
						{isPublic}
						imageUrl={pictureUrl}
						name={organizer?.currentName}
						username={organizer?.username}
						isVerified={true}
						{avgReviewRating}
						{numReviews}
						isLightOverride={false}
						class="py-2 max-w-full"
					/>

					<div
						class="text-sm text-brand-gray-4 dark:text-brand-gray-2 group-hover:text-white transition-all duration-300 w-full {hasCategories &&
						hasCosts
							? 'truncate text-ellipsis'
							: 'text-wrap'}"
					>
						{#if !browser}
							<SkeletonBlock width={`${skeletonWidth}%`} height={'12px'} class="mt-1" />
						{:else}
							{@html trimmedDescription}
						{/if}
					</div>

					{#if hasCategories}
						<div class="flex flex-wrap gap-2 my-2">
							{#each categories as category}
								<span class="text-xs px-2 py-1 rounded-full text-brand-gray-2 bg-brand-gray-2/10">
									{category}
								</span>
							{/each}
						</div>
					{/if}
				</div>

				<div class="flex flex-col w-full mt-2 overflow-hidden">
					{#if hasCosts}
						<p class="text-xs text-brand-gray-4">Estimated Trip Cost</p>
						<div
							class="flex mt-1 mb-4 divide-x divide-brand-gray-4 [&>*]:px-5 [&>*:first-child]:pl-0"
						>
							{#if weekendMinCost}
								<div class="flex flex-col">
									<span class="text-xs text-brand-gray-4">Weekend</span>
									<div class="flex items-baseline gap-0">
										<Amount class="text-xs" amount={toAmount(weekendMinCost)} rounded={true} />
										{#if weekendMaxCost && weekendMaxCost !== weekendMinCost}
											<span class="text-xs text-brand-gray-3">-</span>
											<Amount class="text-xs" amount={toAmount(weekendMaxCost)} rounded={true} />
										{/if}
									</div>
								</div>
							{/if}

							{#if weekMinCost}
								<div class="flex flex-col">
									<span class="text-xs text-brand-gray-4">7 Days</span>
									<div class="flex items-baseline gap-0">
										<Amount class="text-xs" amount={toAmount(weekMinCost)} rounded={true} />
										{#if weekMaxCost && weekMaxCost !== weekMinCost}
											<span class="text-xs text-brand-gray-3">-</span>
											<Amount class="text-xs" amount={toAmount(weekMaxCost)} rounded={true} />
										{/if}
									</div>
								</div>
							{/if}

							{#if monthMinCost}
								<div class="flex flex-col">
									<span class="text-xs text-brand-gray-4">14 Days</span>
									<div class="flex items-baseline gap-0">
										<Amount class="text-xs" amount={toAmount(monthMinCost)} rounded={true} />
										{#if monthMaxCost && monthMaxCost !== monthMinCost}
											<span class="text-xs text-brand-gray-3">-</span>
											<Amount class="text-xs" amount={toAmount(monthMaxCost)} rounded={true} />
										{/if}
									</div>
								</div>
							{/if}
						</div>
					{/if}
					<div class="flex justify-between w-full mt-1 flex-shrink-0">
						<div class="flex items-center space-x-4">
							<TripSummary
								numBlocks={trip?.numBlocks}
								numDestinations={trip?.numDestinations}
								numHighlights={trip?.numHighlights}
								numSources={trip?.numSources}
								isCompact={true}
							/>
						</div>
						{#if basePrice > 0}
							<Amount class="text-sm text-brand-gray-2" amount={toAmount(basePrice ?? 2900)} />
						{/if}
					</div>
				</div>
			</button>
		</div>
		{#if !isPublic && shouldShowReview}
			{#if trip?.yourPurchase?.review}
				<div class="mx-4 mt-4">
					<SleekButton onclick={handleReviewClick}>View Review</SleekButton>
				</div>
			{:else if trip?.yourPurchase}
				<div class="mx-4 mt-4">
					<Button size="xs" onclick={handleReviewClick}>Add Review</Button>
				</div>
			{/if}
		{/if}
	{:else}
		<div class={condensedClasses}>
			<div class="flex items-center w-full max-w-full">
				<div class="relative flex-shrink-0">
					{#if photoUrls?.length > 0}
						<button
							onclick={stopPropagation(handleClick)}
							onmouseenter={handleMouseEnter}
							disabled={isPreview}
							class="h-56 w-[150px] rounded-lg flex-shrink-0 touch-manipulation"
						>
							<PhotoLoop {vertical} photos={photoUrls} interval={800} />
						</button>
					{:else}
						<button
							onclick={stopPropagation(handleClick)}
							onmouseenter={handleMouseEnter}
							disabled={isPreview}
							class="touch-manipulation h-56 w-[150px] bg-brand-gray-4 rounded-lg flex items-center justify-center group-hover:text-white"
						>
							<img
								class="h-24 w-24 p-6 object-cover"
								src={'/visuals/icons/location-pin.svg'}
								alt={trip?.title}
							/>
						</button>
					{/if}
					<img
						src={pictureUrl}
						alt={organizer?.currentName}
						class="h-12 w-12 absolute -bottom-2 -right-2 rounded-full bg-brand-gray-1 ring-4 ring-offset-0 ring-brand-gray-5 border border-brand-gray-2"
					/>
				</div>
				<button
					onclick={stopPropagation(handleClick)}
					onmouseenter={handleMouseEnter}
					disabled={isPreview}
					class={`pl-4 h-56 flex flex-col text-start justify-between truncate text-ellipsis w-full touch-manipulation ${
						isPreview ? '' : 'group-hover:text-white'
					}`}
				>
					<div class="flex flex-col w-full">
						<div
							class="text-lg font-normal text-black dark:text-brand-gray-0 group-hover:text-white w-full truncate text-ellipsis"
						>
							{fixTitleCharacters(trip?.title)}
						</div>

						<div
							class="text-sm text-brand-gray-4 dark:text-brand-gray-3 group-hover:text-white w-full {categories?.length >
								0 &&
							(weekendMinCost || weekMinCost || monthMinCost) &&
							!isFullScreen
								? 'truncate text-ellipsis'
								: 'text-wrap max-h-[200px] overflow-y-hidden'}"
						>
							{#if !browser}
								<SkeletonBlock width={`${skeletonWidth}%`} height={'12px'} class="mt-1" />
							{:else}
								{@html trimmedDescription}
							{/if}
						</div>

						{#if hasCategories}
							<div class="flex flex-wrap gap-2 my-2">
								{#each categories as category}
									<span class="text-xs px-2 py-1 rounded-full text-brand-gray-2 bg-brand-gray-2/10">
										{category}
									</span>
								{/each}
							</div>
						{/if}
					</div>

					<div class="flex flex-col w-full relative">
						{#if hasCosts}
							<p class="text-xs text-brand-gray-4">Estimated Trip Cost</p>
							<div
								class={`flex mt-1 mb-7 divide-x divide-brand-gray-4  ${
									isFullScreen
										? '[&>*]:px-6 [&>*:first-child]:pl-0'
										: '[&>*]:px-3 [&>*:first-child]:pl-0'
								}`}
							>
								{#if weekendMinCost}
									<div class="flex flex-col">
										<span class="text-xs text-brand-gray-4">Weekend</span>
										<div class="flex items-baseline gap-0">
											<Amount class="text-xs" amount={toAmount(weekendMinCost)} rounded={true} />
											{#if weekendMaxCost && weekendMaxCost !== weekendMinCost}
												<span class="text-xs text-brand-gray-3">-</span>
												<Amount class="text-xs" amount={toAmount(weekendMaxCost)} rounded={true} />
											{/if}
										</div>
									</div>
								{/if}

								{#if weekMinCost}
									<div class="flex flex-col">
										<span class="text-xs text-brand-gray-4">7 Days</span>
										<div class="flex items-baseline gap-0">
											<Amount class="text-xs" amount={toAmount(weekMinCost)} rounded={true} />
											{#if weekMaxCost && weekMaxCost !== weekMinCost}
												<span class="text-xs text-brand-gray-3">-</span>
												<Amount class="text-xs" amount={toAmount(weekMaxCost)} rounded={true} />
											{/if}
										</div>
									</div>
								{/if}

								{#if monthMinCost}
									<div class="flex flex-col">
										<span class="text-xs text-brand-gray-4">14 Days</span>
										<div class="flex items-baseline gap-0">
											<Amount class="text-xs" amount={toAmount(monthMinCost)} rounded={true} />
											{#if monthMaxCost && monthMaxCost !== monthMinCost}
												<span class="text-xs text-brand-gray-3">-</span>
												<Amount class="text-xs" amount={toAmount(monthMaxCost)} rounded={true} />
											{/if}
										</div>
									</div>
								{/if}
							</div>
						{/if}
						<div
							class="absolute bottom-0 flex justify-between w-full mt-2 bg-brand-gray-5 pt-2 flex-shrink-0"
						>
							<div class="flex items-center space-x-4">
								<TripSummary
									numBlocks={trip?.numBlocks}
									numDestinations={trip?.numDestinations}
									numHighlights={trip?.numHighlights}
									numSources={trip?.numSources}
									isCompact={true}
								/>
							</div>
							{#if basePrice > 0}
								<Amount class="text-sm text-brand-gray-2" amount={toAmount(basePrice ?? 2900)} />
							{/if}
						</div>
					</div>
				</button>
			</div>
			{#if !isPublic && shouldShowReview}
				{#if trip?.yourPurchase?.review}
					<div class="absolute right-0 bottom-0 mr-4 mt-1">
						<SleekButton onclick={handleReviewClick}>View Review</SleekButton>
					</div>
				{:else if trip?.yourPurchase}
					<div class="absolute right-0 bottom-0 mr-4 mt-1">
						<Button size="xs" onclick={handleReviewClick}>Add Review</Button>
					</div>
				{/if}
			{/if}
		</div>
	{/if}
</div>
